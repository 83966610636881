import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    TRIP_LIST_FAIL,
    TRIP_LIST_SUCCESS,
    NEW_TRIP_FAIL, 
    NEW_TRIP_SUCCESS, 
    UPDATE_TRIP_FAIL, 
    UPDATE_TRIP_SUCCESS,
    DELETE_TRIP_SUCCESS,
    DELETE_TRIP_FAIL,
    TRIP_LIST_BYDATE_SUCCESS,
    TRIP_LIST_BYDATE_FAIL
} from "../constants/tripConstants";

//Save TRIP
export const saveNewTrip = (title, description,code,limitation,exp_date,stores,category,product) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let data={
        title,
        description,
        code,
        limitation,
        exp_date,
        stores,
        category,
        product
    }
    await axios.post(`admin/create-trip`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_TRIP_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_TRIP_FAIL,
            payload: error
        })
    });
}


// Get TRIP
export const getTrip = (data = {}) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });

   
    const defaultParams = {
        pageableSize: 2,
        pageablePage: 0,
        sortingPage: 'ASC',
        status: 'INPROGRESS',
    };

   
    const params = { ...defaultParams, ...data };

    await axios
        .get('ms-data-master-trip/api/v1/trip/', {
            params: params,
        })
        .then(async (response) => {
            dispatch({
                type: LOADING,
                payload: false,
            });
            console.log(response);

            dispatch({
                type: TRIP_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch(async (error) => {
            dispatch({
                type: LOADING,
                payload: false,
            });
            dispatch({
                type: TRIP_LIST_FAIL,
                payload: error,
            });
        });
};



//Get TRIP by date
export const getTripsByDate = (toDate,fromDate,userId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let formData={
        fromDate,toDate,userId
    }
    await axios.post(`tripsbydate`,formData)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })


        dispatch({
            type: TRIP_LIST_BYDATE_SUCCESS,
            payload: response.data.data.upComingTrips
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: TRIP_LIST_BYDATE_FAIL,
            payload: error
        })
    });
}


//Update TRIP
export const updateTrip = (id,title, description,code,limitation,exp_date,stores,category,product) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let data={
        id,title, description,code,limitation,exp_date,stores,category,product
    }
    await axios.post(`admin/edit-trip`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_TRIP_SUCCESS,
            payload: response.data.trip
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_TRIP_FAIL,
            payload: error
        })
    });
}

//DELETE single Notification 
export const deleteTripById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-trip/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_TRIP_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_TRIP_FAIL,
            payload: error
        })
    });
}
