import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import footerlogo from '../assets/images/ugks_logo.png'
import Playstore from '../assets/images/IconsNew/playstore.png'
import Appstore from '../assets/images/IconsNew/appstore.png'
// import Customerapp from 'https://play.google.com/store/apps/details?id=com.ugks.customer&hl=en_IN&gl=US'
// import Driverapp from 'https://play.google.com/store/apps/details?id=com.ugks.driver&hl=en_IN&gl=US'
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider'
import { useLocation, useNavigate } from 'react-router-dom'
import { SET_PREVIOUS_ROUTE } from '../Redux/constants/userConstants'
import { Stack } from '@mui/system';
import { Avatar, Grid } from '@mui/material'
import RegisterMenu from './RegisterMenu';
import Link from '@mui/material/Link';
import AboutUs from '../screens/AboutUs';

const Footer = () => {

  // const { user } = useSelector(state => state.auth)
  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const AboutUs = () => {
    setAnchorElNav(null);
    navigate('/aboutus')
  };
  const TermsConditions = () => {
    setAnchorElNav(null);
    navigate('/termsconditions')
  };
  const PrivacyPolicy = () => {
    setAnchorElNav(null);
    navigate('/privacypolicy')
  };
  const FAQ = () => {
    setAnchorElNav(null);
    navigate('/FAQ')
  };
  const CustomerRegister = () => {
    setAnchorElNav(null);
    navigate('/customerregistration')
  };

  const CompanyRegister = () => {
    setAnchorElNav(null);
    navigate('/b2bregistration')
  };


  return (
    <footer id="footer">
      <Container>

        <Grid container spacing={2}>
          {/* <Grid item md={2} xs={12}>

          
          </Grid> */}
          <Grid item md={3} xs={12}>
          <div className="logo">
              <img src={footerlogo} alt="footer-logo" />
            </div>
{/* 
            <h3 className='column_title'>About</h3> */}
            <p>UGKSLimousine.com is a Global service provider covering major world markets, utilizing a connected Network of owned and Affiliated companies providing quality Chauffeured transportation services worldwide. 
              <span className='readmore_link' onClick={AboutUs}>Read More</span>
            </p>

          </Grid>
          <Grid item md={2} xs={12}>

            <h3 className='column_title'>Policies</h3>
            <p className='link' onClick={TermsConditions} >Terms and Conditions</p>
            <p className='link' onClick={PrivacyPolicy} >Privacy Policy</p>
            <p className='link' onClick={FAQ} >FAQ</p>


          </Grid>
          <Grid item md={2} xs={12}>

            <h3 className='column_title'>Contact Us</h3>
            <p>+65 80565060 </p>
            <p>info@ugkslimousine.com</p>


          </Grid>
          <Grid item md={2} xs={12}>

            <h3 className='column_title'>Registration</h3>
            <p className='link' onClick={CustomerRegister}>Customer Registration</p>
            <p className='link' onClick={CompanyRegister}>Company Registration</p>


          </Grid>
          <Grid item md={3} xs={12}>

<h3 className='column_title'>Customer App</h3>
<a className='app_store_icon' target='_blank' href="https://play.google.com/store/apps/details?id=com.ugks.customer&hl=en_IN&gl=US"><img src={Playstore} /></a>
<a className='app_store_icon' target='_blank' href="https://apps.apple.com/us/app/ugks-customer/id6480458839"><img src={Appstore}/></a>
<h3 className='column_title'>Driver App</h3>
<a className='app_store_icon' target='_blank' href="https://play.google.com/store/apps/details?id=com.ugks.driver&hl=en_IN&gl=US"><img src={Playstore} /></a>
<a className='app_store_icon' target='_blank' href="https://apps.apple.com/us/app/ugks-driver/id6499069755"><img src={Appstore}/></a>

</Grid>

        </Grid>

      </Container>
      <div className="copy-right">
        <p>Copyright UGKS Limousine 2024.All rights reserved.</p>
      </div>
    </footer>
  )

}

export default Footer

