import { Divider, Grid, Typography } from '@mui/material'
import { has } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import Vehicle from './Vehicle'

const VehicleList = () => {
    const { availableVehicles, bookingData } = useSelector(state => state.booking)

    console.log("#vehicles")
    console.log(availableVehicles)
    return (
        <div >
            {availableVehicles?.length > 0 ?
            <Grid  container spacing={2} mt={0}>
                
            {availableVehicles && availableVehicles.map((vehicle, index) => {
                // if(has(vehicle, 'vehicledetails')){
                    return(
                        <Grid id={index} item  xs={12} md={6} lg={6}  >
                            <Vehicle 
                                item={vehicle}
                            />
                        </Grid>
                        
                    )
                // }
                
            })}
            </Grid>: <div style={{display:'flex',justifyContent:'center'}} >
                <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
                    No Vehicle Found !
                </Typography>
             </div>}
        </div>
    )
}

export default VehicleList