import { yupResolver } from '@hookform/resolvers/yup';
import { Image } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Alert, Box, Button, Checkbox, MenuItem, Snackbar, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Unstable_Grid2';
import { isArray } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import countryList from 'react-select-country-list';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import * as yup from "yup";
import BorderBox from '../../components/BorderBox';
import DateInput from '../../components/DateInput';
import DropdownInput from '../../components/DropdownInput';
import ImageInput from '../../components/ImageInput';
import InputField from '../../components/InputField';
import PhoneInputs from '../../components/PhoneInputs';
import TimePicker from '../../components/TimePicker';
import customAxios from '../../CustomAxios';
import { ADD_BOOKING, BOOKING_INPUT, LOADING, ORDER_DATA, RESET_BOOKING_DATA, RESET_ORDER_DATA, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants';
import './style.scss';


const DetailsForm = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [services, setServices] = useState(null)
    const [returnservices, setReturnServices] = useState(null)
    const [open, setOpen] = useState(false);

    const options = useMemo(() => countryList().getData(), [])



    const { user, additionalStops } = useSelector(state => state.auth)
    const { orderData, passengers, bookingType, selectedBooking: booking, currentVehicle: data, selectedVehicle, airportType, itenary, priceplan } = useSelector(state => state.booking)


    const { vehicleAvailability } = useSelector(state => state.mytransfer)
    const { markupLists } = useSelector(state => state.markup)










    const dispatch = useDispatch();

    const [methods, setMethods] = useState('')
    const [returnMethods, setReturnMethods] = useState('')
    const [country, setCountry] = useState('SG')
    const [arival, setArival] = useState('international');
    const [departure, setDeparture] = useState('international');
    const [returnDeparture, setReturnDeparture] = useState('international');
    const [returnArival, setReturnArival] = useState('international');

    const [bufferList, setBufferList] = useState([]);
    const [buffer, setBuffer] = useState(null)
    const [returnBuffer, setReturnBuffer] = useState(null)
    const [loading, setLoading] = useState(false);
    const [countlaugage, setlaugagecount] = useState(null)
    const [mobile, setMobile] = useState(null)

    const [currencySymbol, setcurrencySymbol] = useState('S$');




    const [flightArrival, setFlightArrival] = useState([
        {
            value: 15,
            name: '15 Mins After Flight Arrival'

        },
        {
            value: 30,
            name: '30 Mins After Flight Arrival'

        },
        {
            value: 45,
            name: '45 Mins After Flight Arrival'

        },
        {
            value: 60,
            name: '60 Mins After Flight Arrival'

        },
    ])


    const [cruiseArrival, setCruiseArrival] = useState([
        {
            value: 15,
            name: '15 Mins After Cruise Arrival'

        },
        {
            value: 30,
            name: '30 Mins After Cruise Arrival'

        },
        {
            value: 45,
            name: '45 Mins After Cruise Arrival'

        },
        {
            value: 60,
            name: '60 Mins After Cruise Arrival'

        },
    ])




    const uploadFiles = async (file) => {

        dispatch({
            type: LOADING,
            payload: true
        })

        let formData = new FormData();
        formData.append("itenary", file)

        await customAxios.post(`uploaditenary`, formData)
            .then(async response => {

                dispatch({
                    type: LOADING,
                    payload: false
                })
                dispatch({
                    type: BOOKING_INPUT,
                    payload: {
                        prop: 'itenary',
                        value: response?.data?.data
                    }
                })
            })
            .catch(async error => {
                dispatch({
                    type: LOADING,
                    payload: false
                })
                toast.error(error)

            });
    }

    useEffect(() => {
        console.log("fulldata" + JSON.stringify(data));
        if (data?.addOnServices) {
            let services = data?.addOnServices?.map(serv => {
                return {
                    id: serv?.id,
                    name: serv?.addonservice?.name,
                    price: serv?.rate
                }
            })

            setServices(services)
        }

        /*rubesh */
        if (data?.priceplan?.return?.addOnServices) {
            let return_services = data?.priceplan?.return?.addOnServices?.map(ret_serv => {
                return {
                    id: ret_serv?.id,
                    name: ret_serv?.addonservice?.name,
                    price: ret_serv?.rate
                }
            })
            setReturnServices(return_services);
        }
    }, [])


    useEffect(() => {

        if (user != undefined || user != null) {

            setcurrencySymbol(localStorage.getItem("usercurrency"));
        }
        window.scrollTo(0, 300)
    }, [])


    useEffect(() => {
    console.log("data?.no_hand_carry");
    console.log(data);
        if (user?.role === "customer") {
            let values = {
                callingCode: user.country_code,
                phonenumber: user?.phone
            }

            setMobile(values)
        }
    }, [user])




    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


    const schema = yup.object({
        passenger_name: yup.string().required("First name Required").max(30, 'Maximum character exceeded'),
        passenger_lname: yup.string().required("Last name Required").max(30, 'Maximum character exceeded'),
        mobile: yup.string().required("Phone Number Required").max(30, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email required').max(30, 'Maximum character exceeded'),
        arrival_method: yup.string(),
        flight_no: (booking?.location_from?.type === "airport" && booking?.type === 'point') ? yup.string().required('Flight Number required').max(9) : yup.string().max(9),
        luggage: yup.number().max(bookingType === "PAL" ? data?.checkin : selectedVehicle?.suitcases, "Maximum luggage has been exceeded").typeError(`Maximum luggage has been exceeded`).nullable(true).transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value),
        // arival: yup.string().typeError("Arival method required").when('arrival_method', (arrival_method, schema) => {
        //     return arrival_method === "flight" ? schema.required("Arrival method required")   : schema.string();
        // }),
        // flight_date: yup.string().typeError("Flight date & time required").when('arrival_method', (arrival_method, schema) => {
        //     return arrival_method === "flight" ? schema.required("Flight date & time required")   : schema.min(0);
        // }),
        //flightdate: yup.string().required("Flight date & time required")
        // hand_carry: yup.number().max(bookingType === "PAL" ? data?.no_hand_carry : 99, "Maximum hand carry has been exceeded").typeError(`Maximum hand carry has been exceeded`).nullable(true).transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value),
    }).required();

    const { register, handleSubmit, control, formState: { errors }, getValues, setValue, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: passengers ? passengers : {
            id: uuid(),
            flight_date: orderData?.flight_date ? orderData?.flight_date : moment(booking?.pickupdate).toDate(),
            country: 'SG',
            passenger_name: user?.role === "customer" ? user?.name : '',
            email: user?.role === "customer" ? user?.email : '',
            mobile: user?.role === "customer" ? `${user?.country_code}${user?.phone}` : '',
            arival: 'international',
            passenger_lname: user?.role === "customer" ? user?.lastname : ''
        },
        mode: 'onChange'
    });



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };



    const getBufferTime = async () => {
        setLoading(true);
        await customAxios.get(`auth/getbuffertime/${selectedVehicle.vehicle_type_id
            }`)
            .then(async response => {
                setBuffer(response?.data?.data?.[0]?.buffer_time)
                setBufferList(response?.data?.data)
                setValue('time_related', response?.data?.data?.[0]?.buffer_time)
                setLoading(false);
            })
            .catch(async error => {
                setLoading(false);
                toast.error(error)

            });

    }

    useEffect(() => {
        if (booking?.location_from?.type === "airport") {
            getBufferTime();
        }

        if (booking?.location_from?.type === "cruise") {
            getBufferTime();
        }
    }, [booking?.location_from?.type])



    const onSubmit = async (datas) => {

        console.log('BOOKINGX', booking)
        console.log('DATAX', data)
        console.log('VALUES', datas)
        if (bookingType === 'PAL' && countlaugage > data?.vehicledetails?.vehicle_type?.no_check_in) {
            setOpen(true)
            //return false;
            // setError('luggage', { type: 'custom', message: 'Maximum luggage exceed' });
        }
        // dispatch({
        //     type: PASSENGERS_DETAILS,
        //     payload: datas
        // })
        if (bookingType === "PAL") {
            await populateOrderData(datas)
        }
        else if (bookingType === "MYTRANSFER") {
            await populateMyTransferData(datas)
        }
        dispatch({
            type: ADD_BOOKING
        })

        dispatch({
            type: RESET_BOOKING_DATA
        })

        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'selectedService',
                value: null
            }
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'retselectedService',
                value: null
            }
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'itenary',
                value: null
            }
        })

        // if (user) {
            navigate('/payment')
        // }
        // else {
        //     navigate('/loginregister')
        // }

        localStorage.removeItem("booking");
        const bodyOrder = {
            basicDetails: {
                // id": "24bdfe23-f20d-40a6-bec2-4e99a1f0e9f1",
                name: `${datas?.passenger_name}, ${datas?.passenger_lname}`,
                email: datas?.email,
                phone: datas?.mobile,
                adults: booking?.adults,
                childSeat: booking?.minors,
                babySeat: booking?.baby,
                booster: data?.booster_seats,
                luggage: datas?.luggage ?? 0,
                handCarry: datas?.handCarry ?? 0,
                specialInstruction: datas?.specialinstructions,
                roleType: datas?.roleType ?? 'guest',
                accountName: `${datas?.passenger_name}, ${datas?.passenger_lname}`
            },
            timeLocationDetails: {
                pickupDate: moment(booking?.pickupdate).format('YYYY-MM-DD'),
                pickupTime: moment(booking?.pickupdate).format('HH:mm'),
                customerReference: '',
                pickUpLocationDetails: {
                    placeName: booking?.location_from?.description,
                    latitudePickupLocation: booking?.location_from?.latitude,
                    longitudePickupLocation: booking?.location_from?.longitude
                },
                dropOffLocationDetails: {
                    placeName: booking?.location_to?.description,
                    latitudeDropOffLocation: booking?.location_to?.latitude,
                    longitudeDropOffLocation: booking?.location_to?.longitude
                }
            },
            paymentDetails: {
                id: null,
                paymentType: data?.payment_type ?? 'MANUAL',
                transactionId: null
            },
            arrivalInformationDetails: {
                arrivalMethod: "",
                cruiseName: "",
                disembarkment: "",
                bufferTime: ""
            },
            vehicleDetails: {
                serviceType: data?.service_type,
                vehicleName: data?.vehicletype,
                vehicleAmount: data?.rate,
                isManualPrice: false
            },
            flightDetails: {
                flightType: "INT",
                flightNumber: datas?.flightno,
                flightDate: moment(data?.flight_date).format("YYYY-MM-DD"),
                flightTime: moment(data?.flight_date).format("HH:mm")
            },
            status: "INPROGRESS",
            version: 1,
            createdBy: "system",
            createdAt: null,
            updatedBy: null,
            updatedAt: null,
            deletedBy: null,
            deletedAt: null
        }
        navigate('/payment', { state : bodyOrder })
    };

    const addMoreBooking = async (data) => {

        if (bookingType === 'PAL' && countlaugage > data?.vehicledetails?.vehicle_type?.no_check_in) {
            setOpen(true)
            return false;
            // setError('luggage', { type: 'custom', message: 'Maximum luggage exceed' });
        }
        populateOrderData(data)
        await localStorage.removeItem("booking");
        dispatch({
            type: ADD_BOOKING
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'selectedService',
                value: null
            }
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'itenary',
                value: null
            }
        })
        navigate('/newbooking')
    }

    const populateMyTransferData = (datas) => {




        let inputData = {
            customer_reference: datas?.reference_number,
            arrival: booking?.type === 'point' ? (booking?.location_from?.type === 'airport' || vehicleAvailability?.pickupType === "airport") ? 'Arrival' : booking?.location_to?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
            id: datas?.id,
            location_from: booking?.location_from?.address,
            location_to: booking?.location_to?.address,
            baggage_protection: (booking?.location_to?.type === "airport" && country === "SG") ? true : false,
            name: `${datas?.passenger_name} ${datas?.passenger_lname}`,
            customerFirstName: datas?.passenger_name,
            customerLastName: datas?.passenger_lname,
            country: datas?.country,
            customerCountry: datas?.country,
            email: datas?.email,
            customerEmail: datas?.email,
            phone: datas?.mobile,
            country_code: mobile?.callingCode,
            customerPhone: datas?.mobile,
            cruise_no: datas?.cruise_no,
            disembarkment: moment(datas?.disembarkment).format("hh:mm A"),
            mobile: datas?.mobile,
            namesign: "",
            nationality: "",
            passport: "",
            adults: booking?.adults ? booking?.adults : 0,
            childrens: booking?.childrens ? booking?.childrens : 0,
            minors: booking?.minors ? booking?.minors : 0,
            booster_seats: booking?.minors ? booking?.minors : 0,
            baby_seats: booking?.baby ? booking?.baby : 0,
            suitcase: datas?.luggage ? datas?.luggage : 0,
            carryon: datas?.hand_carry ? datas?.hand_carry : 0,
            specialinstructions: datas?.instructions ? datas?.instructions : '',
            pickupdate: moment(booking?.pickupdate).format("YYYY-MM-DD"),
            pickuptime: moment(booking?.pickupdate).format("hh:mm A"),
            pickup_loc_type: null,
            flightno: getValues('flight_no') && datas?.flight_no ? datas?.flight_no : null,
            flighTiming: getValues('flight_no') && datas?.flight_date ? datas?.flight_date : null,
            flightdate: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("DD-MM-YYYY") : null,
            flighttime: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("hh:mm A") : null,
            time_related: datas?.time_related ? datas?.time_related : null,
            buffer_time: datas?.time_related ? datas?.time_related : null,
            supplier_id: null,
            vehicle_type: null,
            vehicle_id: null,
            driver_id: null,
            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
            created_by: user?.id ? user?.id : null,
            currency: data?.currencyId ? data?.currencyId : "SGD",
            usertype: user ? user?.role : "guest",
            stops: [],
            stopCount: 0,
            payment_type: "card",
            arival: arival ? arival : null,
            booking_data: booking,
            selectedVehicle: selectedVehicle,
            bookingType: bookingType,
            addonItem: null,
            addonservices: null,
            taxPer: null,
            vehicletype: null,
            service_type: null,
            vehicle_image: selectedVehicle?.imageURL,
            agentMarkup: selectedVehicle?.agentmarkup,
            base_rate: selectedVehicle?.price,
            markup: selectedVehicle?.adminMarkup,
            tax: selectedVehicle?.taxValue,
            total_price: selectedVehicle?.total,
            toll: 0,
            extraCharges: selectedVehicle?.extraRate,
            addonService: 0,
            extras: selectedVehicle?.extras,
            itenary: booking?.type === "hour" ? itenary?.itenary : null,
            itenary_id: booking?.type === "hour" ? itenary?.id : null,
            offset: booking?.location_from?.offset,
            offsetdatetime: moment(booking?.pickupdate).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
        }



        dispatch({
            type: ORDER_DATA,
            payload: inputData
        })
    }

    const populateOrderData = async (datas) => {


        let addonService = 0;
        let returnoaddnService = 0;
        let tax = data?.tax;
        let total = data?.total;
        let returnTax = data?.returnTax;
        let returnTotal = data?.returnTotal;
        let service = services?.filter(serv => serv?.checked)
        let returnservice = returnservices?.filter(retserv => retserv?.checked)
        let totalWithoutTax = data?.totalWithoutTax;
        let returnTotalWithoutTax = data?.returnTotalWithoutTax;
        let finalTotal = data?.finalTotal
        let returnPrice = 0;
        await service?.map(serv => {
            addonService += Math.ceil(serv?.price)
        })
        await returnservice?.map(retserv => {
            returnoaddnService += Math.ceil(retserv?.price)
        })

        if (addonService > 0 || returnoaddnService > 0) {
            totalWithoutTax = data?.totalWithoutTax + addonService;
            tax = Math.ceil((totalWithoutTax / 100) * parseFloat(data?.taxPer))

            total = Math.ceil(totalWithoutTax + tax);

            if (booking?.bookingType === "return") {

                returnTotalWithoutTax = data?.returnTotalWithoutTax + returnoaddnService;
                returnTax = Math.ceil((returnTotalWithoutTax / 100) * parseFloat(data?.taxPer))
                returnTotal = Math.ceil(returnTotalWithoutTax + returnTax);
            }

            finalTotal = total + returnTotal

        }


        let selected = {
            ...data,
            services: service,
            addonService,
            totalWithoutTax,
            tax,
            total,
            returnTotalWithoutTax,
            returnTax,
            returnTotal,
            finalTotal
        }






        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: booking,
                selectedVehicle: selected,
                id: 'current'
            }
        })

        if (orderData) {
            let order = orderData.filter(or => or.id !== datas.id);
            dispatch({
                type: RESET_ORDER_DATA,
                payload: order
            })
        }



        let stops = booking?.stops?.map(stop => {
            return {
                cityname: stop?.stop_name?.address,
                latitude: stop?.stop_name?.latitude,
                longitude: stop?.stop_name?.longitude,
            }
        })


        let inputData = {
            customer_reference: datas?.reference_number,
            arrival: booking?.type === 'point' ? booking?.location_from?.type === 'airport' ? 'Arrival' : booking?.location_to?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
            id: datas?.id,
            location_from: booking?.location_from?.address,
            location_to: booking?.location_to?.address,
            baggage_protection: (booking?.location_to?.type === "airport" && country === "SG") ? true : false,
            name: `${datas?.passenger_name} ${datas?.passenger_lname}`,
            country: datas?.country,
            email: datas?.email,
            phone: datas?.mobile,
            mobile: datas?.mobile,
            country_code: mobile?.callingCode,
            namesign: "",
            nationality: "",
            passport: "",
            adults: booking?.adults ? booking?.adults : 0,
            childrens: booking?.childrens ? booking?.childrens : 0,
            cruise_no: datas?.arrival_method === "Cruise" ? datas?.cruise_no : '',
            disembarkment: datas?.arrival_method === "Cruise" ? datas?.disembarkment : '',
            minors: booking?.minors ? booking?.minors : 0,
            booster_seats: booking?.minors ? booking?.minors : 0,
            baby_seats: booking?.baby ? booking?.baby : 0,
            suitcase: datas?.luggage ? datas?.luggage : 0,
            carryon: datas?.hand_carry ? datas?.hand_carry : 0,
            specialinstructions: datas?.instructions ? datas?.instructions : '',
            pickupdate: moment(booking?.pickupdate).format("YYYY-MM-DD"),
            pickuptime: moment(booking?.pickupdate).format("hh:mm A"),
            pickup_loc_type: datas?.arrival_method ? datas?.arrival_method : null,
            flightno: booking?.location_from?.type === "airport" ? getValues('flight_no') && datas?.flight_no ? datas?.flight_no : null : null,
            flighTiming: booking?.location_from?.type === "airport" ? getValues('flight_no') && datas?.flight_date ? datas?.flight_date : null : null,
            flightdate: booking?.location_from?.type === "airport" ? getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("DD-MM-YYYY") : null : null,
            flighttime: booking?.location_from?.type === "airport" ? getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("hh:mm A") : null : null,
            time_related: (booking?.location_from?.type === "airport" || datas?.arrival_method === "Cruise") ? datas?.time_related ? datas?.time_related : null : null,
            buffer_time: (booking?.location_from?.type === "airport" || datas?.arrival_method === "Cruise") ? datas?.time_related ? datas?.time_related : null : null,
            supplier_id: data?.supplier_id,
            vehicle_type: data?.vehicleTypeId,
            vehicle_id: data?.vehicleId,
            driver_id: data?.driverId,
            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
            created_by: user?.id ? user?.id : null,
            currency: data?.currencyId,
            usertype: user ? user?.role : "guest",
            stops: stops ? stops : [],
            stopCount: stops ? stops?.length : 0,
            payment_type: "card",
            arival: arival ? arival : null,
            booking_data: booking,
            selectedVehicle: data?.priceplan,
            bookingType: bookingType,
            addonItem: data?.extraArray,
            addonservices: service,
            returnaddonservices: returnservice,
            taxPer: data?.taxPer,
            vehicletype: data?.vehicletype,
            service_type: data?.service_type,
            vehicle_image: data?.vehicleImage,
            agentMarkup: data?.agentmarkup,
            base_rate: data?.baseRate + data?.additionalStop,
            markup: data?.adminMarkup,
            tax: tax,
            total_price: total,
            toll: data?.toll,
            extraCharges: data?.extras,
            addonService: addonService,
            itenary: booking?.type === "hour" ? itenary?.itenary : null,
            itenary_id: booking?.type === "hour" ? itenary?.id : null,
            adminDiscount: data?.adminDiscount,
            departure_airport_type: booking?.location_to?.type === "airport" ? departure ? departure : null : null,
            departure_flight_no: booking?.location_to?.type === "airport" ? datas?.departure_flight_no ? datas?.departure_flight_no : null : null,
            departure_flight_date: booking?.location_to?.type === "airport" ? datas?.departure_flight_date ? datas?.departure_flight_date : null : null,
            offset: booking?.location_from?.offset,
            offsetdatetime: moment(moment(booking?.pickupdate).utcOffset(booking?.location_from?.offset)).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
            extraSeat: data?.extraSeat,
            additionalStop: data?.additionalStop,
            totalWithoutTax
        }

        dispatch({
            type: ORDER_DATA,
            payload: inputData
        })


        if (booking?.bookingType === "return") {
            let order = orderData?.find(or => or.id === datas.id);
            if (!order) {
                let returnData = {
                    ...inputData,
                    arrival: booking?.type === 'point' ? booking?.location_to?.type === 'airport' ? 'Arrival' : booking?.location_from?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
                    id: uuid(),
                    cruise_no: datas?.return_cruise_no,
                    disembarkment: datas?.return_arrival_method === "Cruise" ? moment(datas?.return_disembarkment).format("hh:mm A") : null,
                    pickup_loc_type: datas?.return_arrival_method ? datas?.return_arrival_method : null,
                    flightno: booking?.location_to?.type === "airport" ? datas?.return_flight_no ? datas?.return_flight_no : null : null,
                    flighTiming: booking?.location_to?.type === "airport" ? datas?.return_flight_date ? datas?.return_flight_date : null : null,
                    flightdate: booking?.location_to?.type === "airport" ? datas?.return_flight_date ? moment(datas?.return_flight_date).format("DD-MM-YYYY") : null : null,
                    flighttime: booking?.location_to?.type === "airport" ? datas?.return_flight_date ? moment(datas?.return_flight_date).format("hh:mm A") : null : null,
                    time_related: (booking?.location_to?.type === "airport" || datas?.return_arrival_method === "Cruise") ? datas?.return_time_related ? datas?.return_time_related : null : null,
                    buffer_time: (booking?.location_to?.type === "airport" || datas?.return_arrival_method === "Cruise") ? datas?.return_time_related ? datas?.return_time_related : null : null,
                    arival: returnArival ? returnArival : null,
                    departure_airport_type: booking?.location_from?.type === "airport" ? returnDeparture : null,
                    departure_flight_no: booking?.location_from?.type === "airport" ? datas?.return_departure_flight_no : null,
                    departure_flight_date: booking?.location_from?.type === "airport" ? datas?.return_departure_flight_date : null,
                    location_from: inputData?.location_to,
                    location_to: inputData?.location_from,
                    pickupdate: moment(booking?.returndate).format("YYYY-MM-DD"),
                    pickuptime: moment(booking?.returndate).format("hh:mm A"),
                    tax: returnTax,
                    total_price: returnTotal,
                    base_rate: data?.priceplan?.return?.rate,
                    totalWithoutTax: returnTotalWithoutTax,
                    extraCharges: data?.returnExtra,
                    addonItem: data?.returnExtraArray,
                    adminDiscount: data?.returnAdminDiscount,
                    agentMarkup: data?.returnAgentMarkup,
                    markup: data?.returnAdminMarkup,
                    offset: booking?.location_to?.offset,
                    offsetdatetime: moment(moment(booking?.returndate).utcOffset(booking?.location_to?.offset)).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
                    booking_data: {
                        ...inputData.booking_data,
                        location_from: inputData.booking_data?.location_to,
                        location_to: inputData.booking_data?.location_from,
                        pickupdate: inputData.booking_data?.returndate
                    }
                }
                dispatch({
                    type: ORDER_DATA,
                    payload: returnData
                })
            }
        }



    }

    const addAddOnService = (checked, id) => {
        let newServ = services?.map(add => {
            if (id === add?.id) {
                return {
                    ...add,
                    checked: checked
                }
            }
            else {
                return add
            }
        })
        setServices(newServ)

        let selectedServices = newServ?.filter(add => add.checked === true)
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'selectedService',
                value: selectedServices
            }
        })
    }

    const returnaddAddOnService = (checked, id) => {
        let retnewServ = returnservices?.map(add => {
            if (id === add?.id) {
                return {
                    ...add,
                    checked: checked
                }
            }
            else {
                return add
            }
        })
        setReturnServices(retnewServ)

        let selectedServices = retnewServ?.filter(add => add.checked === true)
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'retselectedService',
                value: selectedServices
            }
        })
    }
    const returnInformation = () => {
        if (booking?.bookingType === "return") {
            if (booking?.location_to?.type === "airport") {
                return (
                    <div style={{ paddingTop: 20, flexDirection: 'row' }}>
                        <BorderBox title={"Return Trip Arrival Information"}>
                            <Grid container spacing={.5} >
                                <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                    <RadioGroup row style={{ paddingTop: 5 }} >
                                        <FormControlLabel
                                            value="international"
                                            control={<Radio
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setReturnArival('international')
                                                    }
                                                }}
                                                checked={returnArival === "international"}
                                            //name="arival" {...register("arival")} value="international"   
                                            />}
                                            label={<Typography style={{ fontSize: 12 }}>International</Typography>}
                                        />
                                        {country !== "SG" && <FormControlLabel
                                            value="domestic"
                                            control={<Radio
                                                name="arival"
                                                //{...register("arival")}  
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setReturnArival('domestic')
                                                    }
                                                }}
                                                checked={returnArival === "domestic"}
                                            />}
                                            label={<Typography style={{ fontSize: 12 }}>Domestic</Typography>}
                                        />}
                                    </RadioGroup>
                                    <span style={{ fontSize: 10, color: 'red' }}>{errors.arival?.message}</span>
                                </Grid>
                                <Grid xs={12} md={1.5} item sx={{ flexGrow: 1 }}>
                                    <InputField
                                        fieldLabel="Flight No."
                                        fieldName="return_flight_no"
                                        control={control}
                                        error={errors.return_flight_no}
                                    />
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <DateInput
                                        fieldLabel="Flight Date & Time"
                                        minDate={moment(booking?.returndate).toDate()}
                                        //maxDate={moment(booking?.returndate).toDate()}
                                        fieldName="return_flight_date"
                                        control={control}
                                        fieldType="datetime"
                                        error={errors.return_flight_date}
                                        changeDate={(value) => setValue("return_flight_date", value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.3} sx={{ flexGrow: 1 }}>
                                    <DropdownInput
                                        fieldLabel="Buffer Time"
                                        fieldName="return_time_related"
                                        control={control}
                                        error={errors.return_time_related}
                                        values={returnBuffer}
                                        changeValue={(e) => {
                                            setValue('return_time_related', e.target?.value)
                                            setReturnBuffer(e.target?.value)
                                        }
                                        }
                                    >
                                        <MenuItem value={bufferList?.[0]?.buffer_time}> {bufferList?.[0]?.buffer_label}</MenuItem>
                                        {flightArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                            <MenuItem value={val?.value}> {val?.name}</MenuItem>
                                        ))
                                        }
                                    </DropdownInput>
                                </Grid>
                            </Grid>
                        </BorderBox>
                    </div>
                )
            }
            else {
                return (
                    <div style={{ paddingTop: 20, flexDirection: 'row' }}>
                        <BorderBox title={"Return Trip Arrival Information"}>
                            <Grid container spacing={.5} >
                                <Grid item xs={12} md={3} sx={{ flexGrow: 1 }} >
                                    <DropdownInput
                                        fieldLabel="Arrival Method"
                                        fieldName="return_arrival_method"
                                        control={control}
                                        error={errors.return_arrival_method}
                                        changeValue={e => setReturnMethods(e.target.value)}
                                    >
                                        <MenuItem disabled value="">
                                            Select
                                        </MenuItem>
                                        <MenuItem value="Cruise">Cruise</MenuItem>
                                        <MenuItem value="others">Others</MenuItem>
                                    </DropdownInput>
                                </Grid>
                                {returnMethods === "Cruise" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                                    <InputField
                                        fieldLabel="Name of Cruise"
                                        fieldName="return_cruise_no"
                                        control={control}
                                        error={errors.return_cruise_no}
                                    />
                                </Grid>}
                                {returnMethods === "Cruise" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                                    <TimePicker
                                        fieldLabel="Time of Disembarkment"
                                        fieldName="return_disembarkment"
                                        control={control}
                                        error={errors.return_disembarkment}
                                        changeDate={(value) => setValue("return_disembarkment", moment(value, 'YYYY-MM-DD h:mm aa').format('h:mm aa'))}
                                    />
                                </Grid>}
                                {returnMethods === "Cruise" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                                    <DropdownInput
                                        fieldLabel="Buffer Time"
                                        fieldName="return_time_related"
                                        control={control}
                                        error={errors.return_time_related}
                                        values={buffer}
                                        changeValue={(e) => {
                                            setValue('return_time_related', e.target?.value)
                                            setReturnBuffer(e.target?.value)
                                        }
                                        }
                                    >
                                        <MenuItem value={bufferList?.[0]?.buffer_time}> {bufferList?.[0]?.buffer_label}</MenuItem>
                                        {cruiseArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                            <MenuItem value={val?.value}> {val?.name}</MenuItem>
                                        ))}
                                    </DropdownInput>
                                </Grid>}
                            </Grid>
                        </BorderBox>
                    </div>
                )
            }

        }
    }


    const getDepartureInformation = () => {
        if (booking?.location_to?.type === "airport") {
            return (
                <div style={{ marginTop: 25 }}>
                    <BorderBox title={"Departure Information"}>
                        <Grid container>
                            <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                <RadioGroup row style={{ paddingTop: 5 }} >
                                    <FormControlLabel
                                        value="international"
                                        control={<Radio
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setDeparture('international')
                                                }
                                            }}
                                            checked={departure === "international"}
                                        //name="arival" {...register("arival")} value="international"   
                                        />}
                                        label={<Typography style={{ fontSize: 12 }}>International</Typography>}
                                    />
                                    {country !== "SG" && <FormControlLabel
                                        value="domestic"
                                        control={<Radio
                                            name="arival"
                                            //{...register("arival")}  
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setDeparture('domestic')
                                                }
                                            }}
                                            checked={departure === "domestic"}
                                        />}
                                        label={<Typography style={{ fontSize: 12 }}>Domestic</Typography>}
                                    />}
                                </RadioGroup>
                            </Grid>
                            <Grid xs={12} md={1.5} item sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Flight No."
                                    fieldName="departure_flight_no"
                                    control={control}
                                    error={errors.departure_flight_no}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <DateInput
                                    fieldLabel="Flight Date & Time"
                                    minDate={moment(booking?.pickupdate).toDate()}
                                    fieldName="departure_flight_date"
                                    control={control}
                                    fieldType="datetime"
                                    error={errors.flight_date}
                                    changeDate={(value) => setValue("departure_flight_date", value)}
                                />
                            </Grid>
                        </Grid>
                    </BorderBox>
                </div>
            )
        }
    }

    const returnDepartureInformation = () => {
        if (booking?.bookingType === "return" && booking?.location_from?.type === "airport") {
            return (
                <div style={{ marginTop: 25 }}>
                    <BorderBox title={"Return Trip Departure Information"}>
                        <Grid container>
                            <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                <RadioGroup row style={{ paddingTop: 5 }} >
                                    <FormControlLabel
                                        value="international"
                                        control={<Radio
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setReturnDeparture('international')
                                                }
                                            }}
                                            checked={returnDeparture === "international"}
                                        //name="arival" {...register("arival")} value="international"   
                                        />}
                                        label={<Typography style={{ fontSize: 12 }}>International</Typography>}
                                    />
                                    {country !== "SG" && <FormControlLabel
                                        value="domestic"
                                        control={<Radio
                                            name="arival"
                                            //{...register("arival")}  
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setReturnDeparture('domestic')
                                                }
                                            }}
                                            checked={returnDeparture === "domestic"}
                                        />}
                                        label={<Typography style={{ fontSize: 12 }}>Domestic</Typography>}
                                    />}
                                </RadioGroup>
                            </Grid>
                            <Grid xs={12} md={1.5} item sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Flight No."
                                    fieldName="return_departure_flight_no"
                                    control={control}
                                    error={errors.return_departure_flight_no}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <DateInput
                                    fieldLabel="Flight Date & Time"
                                    minDate={moment(booking?.returndate).toDate()}
                                    fieldName="return_departure_flight_date"
                                    control={control}
                                    fieldType="datetime"
                                    error={errors.flight_date}
                                    changeDate={(value) => setValue("return_departure_flight_date", value)}
                                />
                            </Grid>
                        </Grid>
                    </BorderBox>
                </div>
            )
        }
    }

    return (
        <Container maxWidth="xl" >
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                    {`Maximum lauguage ${data?.vehicledetails?.vehicle_type?.no_check_in}`}
                </Alert>
            </Snackbar>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box width={{ xs: "100%", md: "100%" }} paddingLeft={{ xs: 0, md: "0%" }} paddingRight={{ xs: 0, md: "0%" }}>
                    <BorderBox title={"Passenger Details"}>
                        <Grid container spacing={.5} >
                            <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="First Name"
                                    fieldName="passenger_name"
                                    fieldType="text"
                                    control={control}
                                    error={errors.passenger_name}
                                    required
                                    marginTop={0}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Last Name"
                                    fieldName="passenger_lname"
                                    fieldType="text"
                                    control={control}
                                    error={errors.passenger_lname}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Email Address (Booking Notification)"
                                    fieldName="email"
                                    control={control}
                                    error={errors.email}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <DropdownInput
                                    fieldLabel="Country"
                                    fieldName="country"
                                    control={control}
                                    error={errors.country}
                                    changeValue={(e) => {
                                        setCountry(e.target?.value)
                                        if (e?.target?.value === "SG") {
                                            setArival('international')
                                        }

                                    }}
                                >
                                    <MenuItem disabled value="" className='formText'>
                                        Select
                                    </MenuItem>
                                    {options?.map(opt => (
                                        <MenuItem className='formText' value={opt?.value}>{opt?.label}</MenuItem>
                                    ))}
                                </DropdownInput>
                            </Grid>
                            <Grid xs={12} md={6} sx={{ flexGrow: 1 }} >
                                <PhoneInputs
                                    fieldLabel="Mobile"
                                    fieldName="mobile"
                                    control={control}
                                    error={errors.mobile}
                                    required
                                    onChangePhone={setMobile}
                                />
                            </Grid>
                            <Grid xs={12} md={3} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Check-in Luggage"
                                    fieldName="luggage"
                                    fieldType={"number"}
                                    min={1}
                                    control={control}
                                    error={errors.luggage}
                                //onChangedev={onChangedev}
                                />
                            </Grid>
                            <Grid xs={12} md={3} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Hand Carry"
                                    fieldName="hand_carry"
                                    fieldType={"number"}
                                    min={1}
                                    control={control}
                                    error={errors.hand_carry}
                                />
                            </Grid>

                            <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Special Instructions"
                                    fieldName="instructions"
                                    control={control}
                                    // multiline={true}
                                    rows={1}
                                    error={errors.instructions}
                                />
                            </Grid>
                            {(user?.role === 'agent' || user?.role === 'corporate') ? <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Reference Number"
                                    fieldName="reference_number"
                                    fieldType={"text"}
                                    control={control}
                                    error={errors.reference_number}
                                />
                            </Grid> : ''}
                            {booking?.type === "hour" && <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                {/* <FileInput
                                    fieldLabel="Itinerary"
                                    fieldName="itenary"
                                    fieldType={"file"}
                                    control={control}
                                    error={errors.itenary}
                                    onChange={}
                                /> */}
                                <ImageInput
                                    fieldName="itenary"
                                    fieldLabel="Itinerary"
                                    // imageHelperText={"512 * 512"}
                                    control={control}
                                    error={errors.itenary}
                                    type={'application/pdf'}
                                    changeImage={(e) => uploadFiles(e)}
                                    Icon={
                                        <div className='uploadIcon'><Image /></div>}
                                />
                            </Grid>}
                        </Grid>
                    </BorderBox>
                    {isArray(services) && (services?.length > 0 || booking?.location_to?.type === "airport") && <div style={{ paddingTop: 20 }}>
                        <BorderBox title={"Add on Services"}>
                            <Grid container p={0} xs={12} paddingLeft={1}>
                                {services?.map((serv, index) => (
                                    <Grid xs={12} md={3} key={index} spacing={0.5}>
                                        <FormControlLabel
                                            style={{ fontSize: "12px" }}

                                            control={
                                                <Checkbox style={{ fontSize: 12 }} checked={serv?.checked} onChange={(e) => addAddOnService(e.target.checked, serv?.id)} name={serv?.id} />
                                            }
                                            label={<Typography style={{ fontSize: 12 }}>{serv?.name} ({currencySymbol}{Math.ceil(serv?.price)})</Typography>}
                                        />
                                    </Grid>
                                ))}

                                {booking?.location_to?.type === "airport" && <Grid xs={3}>
                                    {/* <FormControlLabel
                                        style={{ fontSize: "12px" }}
                                        control={
                                            <Checkbox style={{ fontSize: 12 }} checked={true} disabled name={"backageProtection"} />
                                        }
                                        label={<Typography style={{ fontSize: 12 }}>Baggage Protection</Typography>}
                                    /> */}
                                </Grid>}

                            </Grid>
                        </BorderBox>
                    </div>}

                    {booking?.bookingType == "return" ?
                        <BorderBox title={"Return Add on Services"}>
                            <Grid container p={0} xs={12} paddingLeft={1}>
                                {returnservices?.map((serv, index) => (
                                    <Grid xs={12} md={3} key={index} spacing={0.5}>
                                        <FormControlLabel
                                            style={{ fontSize: "12px" }}

                                            control={
                                                <Checkbox style={{ fontSize: 12 }} checked={serv?.checked} onChange={(e) => returnaddAddOnService(e.target.checked, serv?.id)} name={serv?.id} />
                                            }
                                            label={<Typography style={{ fontSize: 12 }}>{serv?.name} ({currencySymbol}{Math.ceil(serv?.price)})</Typography>}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </BorderBox>
                        : ""
                    }

                    {booking?.type === "point" && <div style={{ paddingTop: 20 }}>
                        <BorderBox title={"Arrival Information"}>
                            {/* {vehicleAvailability?.pickupType === "airport" &&
                                <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'start'
                                        }}
                                    >
                                        <InputField
                                            fieldLabel="Flight No."
                                            fieldName="flight_no"
                                            control={control}
                                            error={errors.flight_no}
                                            required
                                        />
                                    </div>
                                </Grid>} */}
                            {(booking?.type === "point") && <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginTop: 4,
                                        marginBottom: 4
                                    }}
                                >
                                    <span>
                                        <ErrorIcon style={{ width: 25, height: 25, marginRight: 10 }} />
                                    </span>
                                    <span>
                                        <Typography className='loctext' >Flight/Ferry arrival cannot be tracked without proper information!</Typography>
                                    </span>
                                </div>
                                <Grid container spacing={1}>
                                    {booking?.location_from?.type === "point" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }} >
                                        <DropdownInput
                                            fieldLabel="Arrival Method"
                                            fieldName="arrival_method"
                                            control={control}
                                            error={errors.arrival_method}
                                            changeValue={e => setMethods(e.target.value)}
                                        >
                                            <MenuItem disabled value="">
                                                Select
                                            </MenuItem>
                                            <MenuItem value="Cruise">Cruise</MenuItem>
                                            <MenuItem value="others">Others</MenuItem>
                                        </DropdownInput>

                                    </Grid>}
                                </Grid>
                                <Grid container spacing={1}>
                                    {booking?.location_from?.type === "airport" && <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                        <RadioGroup row style={{ paddingTop: 5 }} >
                                            <FormControlLabel
                                                value="international"
                                                control={<Radio
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setArival('international')
                                                        }
                                                    }}
                                                    checked={arival === "international"}
                                                //name="arival" {...register("arival")} value="international"   
                                                />}
                                                label={<Typography style={{ fontSize: 12 }}>International</Typography>}
                                            />
                                            {country !== "SG" && <FormControlLabel
                                                value="domestic"
                                                control={<Radio
                                                    name="arival"
                                                    //{...register("arival")}  
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setArival('domestic')
                                                        }
                                                    }}
                                                    checked={arival === "domestic"}
                                                />}
                                                label={<Typography style={{ fontSize: 12 }}>Domestic</Typography>}
                                            />}
                                        </RadioGroup>
                                        <span style={{ fontSize: 10, color: 'red' }}>{errors.arival?.message}</span>
                                    </Grid>}

                                    {(booking?.location_from?.type === "airport") &&
                                        <Grid xs={12} md={2} item sx={{ flexGrow: 1 }}>
                                            <InputField
                                                fieldLabel="Flight No."
                                                fieldName="flight_no"
                                                control={control}
                                                error={errors.flight_no}
                                            />
                                        </Grid>}
                                    {(booking?.location_from?.type === "airport") &&
                                        <Grid xs={12} md={3}>
                                            <DateInput
                                                fieldLabel="Flight Date & Time"
                                                minDate={moment(booking?.pickupdate).toDate()}
                                                maxDate={moment(booking?.pickupdate).toDate()}
                                                fieldName="flight_date"
                                                control={control}
                                                fieldType="datetime"
                                                error={errors.flight_date}
                                                changeDate={(value) => setValue("flight_date", value)}
                                            />
                                        </Grid>}
                                    {(booking?.location_from?.type === "airport") &&
                                        <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                            <DropdownInput
                                                fieldLabel="Buffer Time"
                                                fieldName="time_related"
                                                control={control}
                                                error={errors?.time_related}
                                                values={buffer ? buffer : 0}
                                                changeValue={(e) => {
                                                    setValue('time_related', e.target?.value)
                                                    setBuffer(e.target?.value)
                                                }
                                                }
                                            >
                                                {bufferList?.[0]?.buffer_time && <MenuItem value={bufferList?.[0]?.buffer_time ? bufferList?.[0]?.buffer_time : 0}> {flightArrival?.find(res => res.value === bufferList?.[0]?.buffer_time)?.name}</MenuItem>}
                                                {flightArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                                    <MenuItem value={val?.value}> {val?.name}</MenuItem>
                                                ))
                                                }
                                            </DropdownInput>
                                        </Grid>}
                                    {methods === "Cruise" && <Grid item xs={12} md={5} sx={{ flexGrow: 1 }}>
                                        <InputField
                                            fieldLabel="Name of Cruise"
                                            fieldName="cruise_no"
                                            control={control}
                                            error={errors.cruise_no}
                                        />
                                    </Grid>}
                                    {methods === "Cruise" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                                        {/* <InputField
                                            fieldLabel="Time of disembarkment"
                                            fieldName="disembarkment"
                                            control={control}
                                            error={errors.disembarkment}
                                        /> */}
                                        <TimePicker
                                            fieldLabel="Time of Disembarkment"
                                            fieldName="disembarkment"
                                            control={control}
                                            error={errors.disembarkment}

                                            changeDate={(value) => setValue("disembarkment", moment(value, 'YYYY-MM-DD h:mm aa').format('h:mm aa'))}
                                        />
                                    </Grid>}
                                    {methods === "Cruise" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                                        <DropdownInput
                                            fieldLabel="Buffer Time"
                                            fieldName="time_related"
                                            control={control}
                                            error={errors.time_related}
                                            values={buffer}
                                            changeValue={(e) => {
                                                setValue('time_related', e.target?.value)
                                                setBuffer(e.target?.value)
                                            }
                                            }
                                        >
                                            <MenuItem value={bufferList?.[0]?.buffer_time}> {cruiseArrival?.find(res => res.value === bufferList?.[0]?.buffer_time)?.name}</MenuItem>
                                            {cruiseArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                                <MenuItem value={val?.value}> {val?.name}</MenuItem>
                                            ))
                                            }
                                        </DropdownInput>
                                    </Grid>}
                                </Grid>
                            </>}
                        </BorderBox>

                        {getDepartureInformation()}
                        {returnInformation()}
                        {returnDepartureInformation()}

                    </div>}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',

                            paddingBottom: 100
                        }}
                    >
                        {/* {bookingType === "PAL" && <Button
                            onClick={handleSubmit(addMoreBooking)}
                            variant="filled"
                            style={{
                                backgroundColor: '#333333',
                                borderRadius: 4,
                                width: 165,
                                marginRight: '3%',
                                padding: 5,
                                height: 30
                            }}
                            endIcon={<Add style={{ fontSize: 26, color: 'white' }} />}
                        >
                            <Typography className='buttonText'>ADD MORE BOOKING</Typography>
                        </Button>} */}
                        <Button
                            type="submit"
                            variant="filled"
                            style={{

                            }}
                            className="btn-primary"
                            endIcon={<KeyboardDoubleArrowRightIcon style={{ fontSize: 26, color: 'white' }} />}>
                            <Typography className='buttonText'>PROCEED</Typography>
                        </Button>
                    </div>
                </Box>
            </form>
        </Container>
    )
}

export default DetailsForm

