import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import "typeface-nunito";
import App from './App';
import './index.css';
import store from './Redux/store';
import reportWebVitals from './reportWebVitals';

const stripePromise = loadStripe('pk_live_51LIXu8CIKs3DSYcXuTc4aqFjGpF4yZ6z4OW8actD8ezcLYLzkoYU31PqQ5OeQM8aPUSJapYpyfcmWUOhpDLIePB800T4hB7lvw');


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
		<Elements stripe={stripePromise}>
			<App />
		</Elements>
		</GoogleOAuthProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
