import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Logo from '../assets/images/ugks_logo.png'
import { useNavigate } from 'react-router-dom';
import DropDownMenu from './DropDownMenu';
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import instaLogo from '../assets/images/instagram.jpg'
import Divider from '@mui/material/Divider'
import RegisterMenu from './RegisterMenu';
import { useDispatch } from 'react-redux'
import { RESET_USER } from '../Redux/constants/userConstants';
import { BOOKING_DATA, REMOVE_ORDER_ITEM, RESET_BOOKING } from '../Redux/constants/bookingConstants';

const HomeTopBar = ({ activeMenu }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const Home = () => {
    setAnchorElNav(null);
    navigate('/home')
  };
  const AboutUs = () => {
    setAnchorElNav(null);
    navigate('/aboutus')
  };
  const Services = () => {
    setAnchorElNav(null);
    navigate('/services')
  };
  const ContactUs = () => {
    setAnchorElNav(null);
    navigate('/contactus')
  };
  const handleNavMenu1 = () => {
    setAnchorElNav(null);
    navigate('/dashboard')
  };
  const handleNavMenu2 = () => {
    setAnchorElNav(null);
    navigate('/wallet')
  };
  const handleNavMenu3 = () => {
    setAnchorElNav(null);
    navigate('/topup')
  };
  const handleNavMenu4 = () => {
    setAnchorElNav(null);
    navigate('/profile')
  };
  const handleNavMenu5 = () => {
    setAnchorElNav(null);
    navigate('/markup')
  };
  const handleNavMenu6 = () => {
    setAnchorElNav(null);
    navigate('/user')
  };
  const handleNavMenu7 = () => {
    setAnchorElNav(null);
    navigate('/role')
  };
  const handleNavMenu8 = async () => {
    setAnchorElNav(null);
    dispatch({
      type: RESET_USER
    })
    await localStorage.removeItem("user")
    await localStorage.removeItem("usercurrency")
    navigate('/home')
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const CustomerRegister = () => {
    setAnchorElNav(null);
    navigate('/customerregistration')
  };

  const CompanyRegister = () => {
    setAnchorElNav(null);
    navigate('/b2bregistration')
  };


  const resetdata = () => {
    dispatch({
      type: RESET_BOOKING,
    })


  }

  return (

    <AppBar position="static" color="default" className="header-navbar">
      <Container maxWidth="100%" height="97" width="100%" style={{ paddingLeft: 10, paddingRight: 10 }}>
        <Toolbar disableGutters >
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <img src={Logo} style={{ width: 153, height: 50 }} onClick={resetdata} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} style={{ justifyContent: 'end' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon className='menuicon' />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
             <span onClick={Home} >{activeMenu == '1' ? <Typography className='active'>Home</Typography> : <Typography className='inactive'>Home</Typography>}</span>
            <span onClick={AboutUs} >{activeMenu == '2' ? <Typography className='active'>About Us</Typography> : <Typography className='inactive'>About Us</Typography>}</span>
            <span onClick={Services} >{activeMenu == '3' ? <Typography className='active'>Services</Typography> : <Typography className='inactive'>Services</Typography>}</span>
            <span onClick={ContactUs}>{activeMenu == '4' ? <Typography className='active'>Contact Us</Typography> : <Typography className='inactive'>Contact Us</Typography>}</span>
            <span onClick={CustomerRegister}>{activeMenu == '5' ? <Typography className='active'>Customer Registration</Typography> : <Typography className='inactive'>Customer Registration</Typography>}</span>
            <span onClick={CompanyRegister}>{activeMenu == '6' ? <Typography className='active'>Company Registration</Typography> : <Typography className='inactive'>Company Registration</Typography>}</span>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <img src={Logo} style={{ width: 153, height: 50 }} onClick={resetdata} />
          </Typography>
          <Box className="navbar-menu" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <span onClick={Home} >{activeMenu == '1' ? <Typography className='active'>Home</Typography> : <Typography className='inactive'>Home</Typography>}</span>
            <span onClick={AboutUs} >{activeMenu == '2' ? <Typography className='active'>About Us</Typography> : <Typography className='inactive'>About Us</Typography>}</span>
            <span onClick={Services} >{activeMenu == '3' ? <Typography className='active'>Services</Typography> : <Typography className='inactive'>Services</Typography>}</span>
            <span onClick={ContactUs}>{activeMenu == '4' ? <Typography className='active'>Contact Us</Typography> : <Typography className='inactive'>Contact Us</Typography>}</span>
            <span onClick={CustomerRegister}>{activeMenu == '5' ? <Typography className='active'>Customer Registration</Typography> : <Typography className='inactive'>Customer Registration</Typography>}</span>
            <span onClick={CompanyRegister}>{activeMenu == '6' ? <Typography className='active'>Company Registration</Typography> : <Typography className='inactive'>Company Registration</Typography>}</span>
          </Box>
          <Box className="user-navbarmenu" >

            <span className='loginmenu' style={{}} onClick={() => navigate("/signin")}>
              <Person sx={{ display: { xs: 'flex', md: 'flex' }, mr: 0 }} style={{}} /> Login
            </span>

            {/* <span className='registermenu' style={{}}>
                    <RegisterMenu menuname={"Register"}/>
                </span> */}

            {/* <FacebookRoundedIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 0 }} style={{}}/>
                <Divider orientation="vertical" color='white' variant="middle" flexItem/>
                <img src={instaLogo} style={{ width: 25, height: 25,marginRight:50 }} />   */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HomeTopBar;
